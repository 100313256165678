import React from 'react';
import ReactDOM from 'react-dom/client';
import {HashRouter} from 'react-router-dom';
import App from './App';
import 'antd/dist/antd.css';
import './App.scss';
import {AppBlockchainProvider} from './contexts/BlockchainContext';
import ScrollToTop from './ScrollToTop';
import {useQuery, useMutation, useQueryClient, QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {WalletProvider} from '@suiet/wallet-kit';
import '@suiet/wallet-kit/style.css';
import {AppUIProvider} from './contexts/AppContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

const queryClient = new QueryClient();

root.render(
  <React.Fragment>
    <HashRouter>
      <QueryClientProvider client={queryClient}>
        <WalletProvider>
          <AppUIProvider>
            <AppBlockchainProvider>
              <ScrollToTop />
              <App />
            </AppBlockchainProvider>
          </AppUIProvider>
        </WalletProvider>
      </QueryClientProvider>
    </HashRouter>
  </React.Fragment>
);
