import { MoveCallTransaction, SignableTransaction } from '@mysten/sui.js';
import { strToByteArray } from '../utils';
import { 
    BuildMintToSenderParams, 
    BuildCreateCollectionParams,
    BuildListParams,
    BuildDelistAndTakeParams,
    BuildPurchaseAndTakeMutParams,
} from './types';
  
export const buildMintToSenderTx = (params: BuildMintToSenderParams): any => {
    const keys: string[] = [];
    const values: string[] = [];
    const { attributes } = params;
    Object.keys(attributes).forEach((key) => {
      keys.push(key);
      values.push(attributes[key]);
    });
  
    return {
      packageObjectId: params.packageObjectId,
      module: params.moduleName,
      function: 'mint_to_sender',
      typeArguments: [],
      arguments: [
        params.collectionManager,
        params.name,
        params.description,
        params.url,
        params.collectionId,
        keys.map((_) => strToByteArray(_)),
        values.map((_) => strToByteArray(_)),
        params.isMutable,
      ],
      gasBudget: 30000,
    };
};

export const buildCreateCollectionTx = (params: BuildCreateCollectionParams): any => {
  return {
    packageObjectId: params.packageObjectId,
    module: params.moduleName,
    function: 'create_collection',
    typeArguments: [],
    arguments: [
      params.collectionManager,
      params.name,
      params.description,
      params.symbol,
      params.tags,
      params.isMutable,
      params.royaltyFee
    ],
    gasBudget: 30000,
  };
};

export const buildListTx = (params: BuildListParams): any => {
  return {
    packageObjectId: params.packageObjectId,
    module: params.moduleName,
    function: 'list',
    typeArguments: [],
    arguments: [
      params.marketplace,
      params.item,
      params.price,
    ],
    gasBudget: 30000,
  };
};

export const buildDelistAndTakeTx = (params: BuildDelistAndTakeParams): any => {
  return {
    packageObjectId: params.packageObjectId,
    module: params.moduleName,
    function: 'delist_and_take',
    typeArguments: [],
    arguments: [
      params.marketplace,
      params.itemId,
    ],
    gasBudget: 30000,
  };
};

export const buildPurchaseAndTakeMutTx = (params: BuildPurchaseAndTakeMutParams): any => {
  return {
    packageObjectId: params.packageObjectId,
    module: params.moduleName,
    function: 'purchase_and_take_mut',
    typeArguments: ['sui::sui::SUI'],
    arguments: [
      params.marketplace,
      params.itemId,
      params.collection,
      params.paid,
    ],
    gasBudget: 30000,
  };
};
//-------------------------------------------
// Wallet funcs
export const buildMintToSenderWalletTx = (params: BuildMintToSenderParams): SignableTransaction => {
  return {
    kind: 'moveCall',
    data: buildMintToSenderTx(params)
  };
};

export const buildCreateCollectionWalletTx = (params: BuildCreateCollectionParams): SignableTransaction => {
  return {
    kind: 'moveCall',
    data: buildCreateCollectionTx(params)
  };
};

export const buildListWalletTx = (params: BuildListParams): SignableTransaction => {
  return {
    kind: 'moveCall',
    data: buildListTx(params)
  };
}

export const buildDelistAndTakeWalletTx = (params: BuildDelistAndTakeParams): SignableTransaction => {
  return {
    kind: 'moveCall',
    data: buildDelistAndTakeTx(params)
  };
}

export const buildPurchaseAndTakeMutWalletTx = (params: BuildPurchaseAndTakeMutParams): SignableTransaction => {
  return {
    kind: 'moveCall',
    data: buildPurchaseAndTakeMutTx(params)
  };
} 

