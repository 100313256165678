import {
  Coin,
  getObjectExistsResponse,
  getObjectId,
  JsonRpcProvider,
  ObjectId,
  SuiMoveObject,
  SuiObject,
} from '@mysten/sui.js';

const provider = new JsonRpcProvider(process.env.REACT_APP_SUI_ENDPOINT);

export const SUI_SYSTEM_STATE_OBJECT_ID = '0x0000000000000000000000000000000000000005';

export const ownedObjects = (objects: any, address: string) => {
  if (address) {
    return objects.filter(
      ({owner}: any) => typeof owner === 'object' && 'AddressOwner' in owner && owner.AddressOwner === address
    );
  }
  return [];
};

export const accountCoinsSelector = (allSuiObjects: any) => {
  return allSuiObjects.filter((el: any) => Coin.isCoin(el.data)).map((aCoin: any) => aCoin.data as SuiMoveObject);
};

export const accountNftSelector = (allSuiObjects: any) => {
  return allSuiObjects.filter((el: any) => !Coin.isCoin(el.data));
};
export const fetchAllOwnedAndRequiredObjects = async (address: string) => {
  const allSuiObjects: SuiObject[] = [];

  if (address) {
    const allObjectRefs = await provider.getObjectsOwnedByAddress(`${address}`);
    const objectIDs = allObjectRefs.map((anObj) => anObj.objectId);
    objectIDs.push(SUI_SYSTEM_STATE_OBJECT_ID);
    const allObjRes = await provider.getObjectBatch(objectIDs);
    for (const objRes of allObjRes) {
      const suiObj = getObjectExistsResponse(objRes);
      if (suiObj) {
        allSuiObjects.push(suiObj);
      }
    }
  }
  return allSuiObjects;
};

export const fetchObjectByIds = async (ids: string[]) => {
  const allSuiObjects: SuiObject[] = [];
  const allObjRes = await provider.getObjectBatch(ids);
  for (const objRes of allObjRes) {
    const suiObj = getObjectExistsResponse(objRes);
    if (suiObj) {
      allSuiObjects.push(suiObj);
    }
  }
  return allSuiObjects;
};

export const getObjectById = async (address: string) => {
  const objRes = await provider.getObject(address);
  const suiObj = getObjectExistsResponse(objRes);
  return suiObj;
};

export const getAllObjectOfAddress = async (address: string) => {
  const allObjects = await fetchAllOwnedAndRequiredObjects(address);
  const allSuiObjects = ownedObjects(allObjects, address);
  return allSuiObjects;
};

// return an aggregate balance for each coin type
export const accountAggregateBalancesSelector = (coins: any) => {
  return coins.reduce((acc: any, aCoin: any) => {
    const coinType = Coin.getCoinTypeArg(aCoin);
    if (coinType) {
      if (typeof acc[coinType] === 'undefined') {
        acc[coinType] = 0;
      }

      acc[coinType] += Number(Coin.getBalance(aCoin));
    }
    return acc;
  }, {} as Record<string, number>);
};

export const convertBalance = (balance) => {
  if (!balance) return 0;
  return (balance / Math.pow(10, 9)).toLocaleString();
};

export const convertTotalPublicSales = (phase01_amount, phase02_amount, phase03_amount) => {
  return (
    phase01_amount / Math.pow(10, 9) +
    phase02_amount / Math.pow(10, 9) +
    phase03_amount / Math.pow(10, 9)
  ).toLocaleString();
};

export const current_phase = (phases: any[] = []): any => {
  let current_time = Math.floor(Date.now() / 1000);
  let ret = {};
  // Temp reverse to get current phase is phase 1 due to test FE
  [...phases]?.reverse().forEach((phase) => {
    let phase_data = phase?.fields?.phase_data?.fields;
    if (phase_data?.enable) {
      if (current_time >= phase_data?.start_time && current_time < phase_data?.end_time) {
        ret = phase_data;
      }
    }
  });
  return ret;
};

export const secondToDate = (second: number) => {
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }).format(second * 1000);
};

export const secondToDateNoSec = (second: number) => {
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }).format(second * 1000);
};

export const convertBalanceNumber = (balance) => {
  if (!balance) return 0;
  return Number((balance / Math.pow(10, 9)).toFixed(6)).toLocaleString();
};

export const convertRewardsBalance = (balance) => {
  return Number((balance / Math.pow(10, 9)).toFixed(9)).toLocaleString();
};

export const selectCoinSetWithCombinedBalanceGreaterThanOrEqual = (coins, amount, exclude) => {
  const sortedCoins = Coin.sortByBalance(coins.filter((c) => !exclude.includes(Coin.getID(c))));

  const total = Coin.totalBalance(sortedCoins);
  // return empty set if the aggregate balance of all coins is smaller than amount
  if (total < amount) {
    return [];
  } else if (total === amount) {
    return sortedCoins;
  }

  let sum = BigInt(0);
  let ret = [];
  while (sum < total) {
    // prefer to add a coin with smallest sufficient balance
    const target = amount - Number(sum);
    const coinWithSmallestSufficientBalance = sortedCoins.find((c) => Coin.getBalance(c)! >= target);
    if (coinWithSmallestSufficientBalance) {
      ret.push(coinWithSmallestSufficientBalance);
      break;
    }

    const coinWithLargestBalance = sortedCoins.pop()!;
    ret.push(coinWithLargestBalance);
    sum += Coin.getBalance(coinWithLargestBalance)!;
  }

  return Coin.sortByBalance(ret);
};

export function getID(obj): ObjectId {
  if (obj.dataType === 'moveObject') {
    return obj.fields.id.id;
  }
  return getObjectId(obj);
}

export function selectCoinWithBalanceGreaterThanOrEqual(coins: any, amount: bigint, exclude: any) {
  coins.forEach((coin) => {
    console.log({data_checked: Coin.getBalance(coin)});
  });
  return coins.find((c) => !exclude.includes(getID(c)) && Coin.getBalance(c)! >= amount);
}

export function selectCoinWithBalanceEqual(coins: any, amount: bigint, exclude: any) {
  return coins.find((c) => !exclude.includes(getID(c)) && Coin.getBalance(c)! === amount);
}
