import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import PageTitle from '@/components/pagetitle/PageTitle';
import {Link, useNavigate, useParams} from 'react-router-dom';
import img1 from '@/assets/images/item-details.jpg';
import avt1 from '@/assets/images/author/author-detail-3.png';
import data from '@/assets/fake-data/data-hotpick';
import icon1 from '@/assets/images/icon/rain1.svg';
import icon2 from '@/assets/images/icon/rain2.svg';
import icon3 from '@/assets/images/icon/ethe.svg';
import CardModal from '@/components/layouts/CardModal';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import avtd1 from '@/assets/images/author/author-detail-1.png';
import avtd2 from '@/assets/images/author/author-detail-2.png';
import avtd3 from '@/assets/images/author/authour-bid-1.png';
import avtd4 from '@/assets/images/author/authour-bid-2.png';
import avtd5 from '@/assets/images/author/authour-bid-3.png';
import avtd6 from '@/assets/images/author/author-history-1.jpg';
import avtd7 from '@/assets/images/author/author-history-2.jpg';
import SUIIcon from '@/assets/images/svg/sui-icon.svg';
import {useBlockchainContext} from '@/contexts/BlockchainContext';
import {useCollectionQuery, useNFTQuery, useUpdateNFTMutation} from '@/hooks';
import {useNFTProtocolContext} from '@/contexts/NFTProtocolContext';
import {
  convertBalanceNumber,
  convertRewardsBalance,
  fetchObjectByIds,
  getAllObjectOfAddress,
  getID,
  getObjectById,
} from '@/utils/SuiObject';
import {Coin} from '@mysten/sui.js';
import {convertu64, priceToNumber, shortAddress} from '@/utils/utils';
import './style.scss';
import {message} from 'antd';

let regexp = /^\d+\.?\d{0,6}$/;

DetailNftPage.propTypes = {};

function DetailNftPage(props) {
  const [modalShow, setModalShow] = useState(false);
  const params = useParams();

  const {nftId} = params;
  const [NFTInfo, setNFTInfo] = useState({});
  const [priceNft, setPriceNft] = useState('0.005');
  const {data: dataNft, refetch} = useNFTQuery({nftId});
  const {data: dataCollection} = useCollectionQuery({collectionId: dataNft?.collectionId});
  const {mutateAsync: updateNft} = useUpdateNFTMutation();
  const collectionInfo = dataCollection?.[0]?.dataObject?.ret?.collection;
  const launchpadYamlInfo = dataCollection?.[0]?.dataObject?.data?.launchpadYaml;
  const {
    saleOffNft,
    saleOnNft,
    buyNftCert,
    claimNftCert,
    createMarketsOnInventory,
    listingOnInventory,
    saleOnMarket,
    saleOffMarket,
  } = useNFTProtocolContext();
  const {addressWallet} = useBlockchainContext();
  const [tabDetails, setTabDetails] = useState([
    {
      id: 1,
      heading: 'Current Owner',
      avt: avtd1,
      name: '',
    },
    {
      id: 2,
      heading: 'Creator',
      avt: avtd2,
      name: '',
    },
  ]);
  const [tabBid] = useState([
    {
      id: 1,
      avt: avtd3,
      price: '1.35 ETH',
      name: 'carlisle',
      time: '3/26/2022, 7:28 AM',
    },
    {
      id: 2,
      avt: avtd4,
      price: '1.35 ETH',
      name: 'carlisle',
      time: '3/26/2022, 7:28 AM',
    },
    {
      id: 3,
      avt: avtd5,
      price: '1.35 ETH',
      name: 'carlisle',
      time: '3/26/2022, 7:28 AM',
    },
    {
      id: 4,
      avt: avtd3,
      price: '1.35 ETH',
      name: 'carlisle',
      time: '3/26/2022, 7:28 AM',
    },
    {
      id: 5,
      avt: avtd4,
      price: '1.35 ETH',
      name: 'carlisle',
      time: '3/26/2022, 7:28 AM',
    },
    {
      id: 6,
      avt: avtd5,
      price: '1.35 ETH',
      name: 'carlisle',
      time: '3/26/2022, 7:28 AM',
    },
  ]);

  const [tabHistory] = useState([
    {
      id: 1,
      name: 'Mason Woodward',
      avt: avtd6,
    },
    {
      id: 2,
      name: 'Violet Pascall ',
      avt: avtd7,
    },
    {
      id: 3,
      name: 'Camilla Hudson ',
      avt: avtd6,
    },
    {
      id: 4,
      name: 'Derick Reed ',
      avt: avtd7,
    },
    {
      id: 5,
      name: 'Mason Woodward',
      avt: avtd6,
    },
  ]);

  useEffect(() => {
    getNftInfo();
  }, [dataNft]);

  const collectionTypeArg = useMemo(() => {
    const str = dataCollection?.[0]?.dataObject?.ret?.ids?.listCollectionId?.[0]?.objectType;
    return str?.substring(str.indexOf('<') + 1, str.lastIndexOf('>'));
  }, [dataCollection]);

  const getNftInfo = async () => {
    console.log(dataNft, 'dataNftdataNftdataNft');
    if (dataNft) {
      const res = await getObjectById(dataNft?.dataObject?.inventoryId);
      console.log('resInven', res);
      setNFTInfo({
        ...res?.data,
        live: !!res?.data?.fields?.live?.fields?.contents?.[0]?.fields?.value,
        checkList: !!res?.data?.fields?.live?.fields?.contents?.length,
      });
    }
  };

  const getListingId = () => {
    return dataCollection?.[0]?.dataListAndMarketObject?.effects?.events.filter((el) =>
      el?.newObject?.objectType?.includes('listing::Listing')
    );
  };

  const listingNft = async () => {
    const listings = getListingId();
    if (NFTInfo?.live) {
      await saleOffMarket(collectionTypeArg, [
        dataNft?.dataObject?.listingId,
        dataNft?.dataObject?.inventoryId,
        dataNft?.dataObject?.marketId,
      ]);
      await updateNft({
        id: dataNft.id,
        data: {saleIndex: 0},
      });
    } else {
      let marketId = dataNft?.dataObject?.marketId;
      if (!NFTInfo?.checkList) {
        const resTrx = await createMarketsOnInventory(collectionTypeArg, [
          dataNft?.dataObject?.inventoryId,
          convertu64(priceNft * Math.pow(10, 9)),
          false,
        ]);
        const market = resTrx?.effects?.events.find((el) =>
          el?.newObject?.objectType?.includes('::fixed_price::FixedPriceMarket')
        );
        marketId = market?.newObject?.objectId;
        await listingOnInventory(collectionTypeArg, [dataNft?.dataObject?.listingId, dataNft?.dataObject?.inventoryId]);
      }

      await saleOnMarket(collectionTypeArg, [
        dataNft?.dataObject?.listingId,
        dataNft?.dataObject?.inventoryId,
        marketId,
      ]);
      await updateNft({
        id: dataNft.id,
        data: {saleIndex: +priceNft, dataObject: {...dataNft?.dataObject, marketId: marketId}},
      });
      setNFTInfo((old) => ({
        ...old,
        live: !old.live,
      }));
    }
    // refetch();
  };

  let imageUrl = useMemo(() => dataCollection?.[0]?.data && JSON.parse(dataCollection?.[0]?.data), [dataCollection]);

  const buyNft = async () => {
    console.log(dataNft?.launchpadId);
    if (NFTInfo?.live) {
      const res = await buyNftCert(
        collectionTypeArg,
        dataNft?.dataObject?.listingId,
        dataNft?.dataObject?.inventoryId,
        dataNft?.dataObject?.marketId,
        dataNft?.saleIndex * Math.pow(10, 9),
        collectionInfo?.witnessTypeArg
      );
      console.log(res, 'buynft');
      if (res) {
        await updateNft({
          id: dataNft.id,
          data: {nftOwner: addressWallet},
        });

        refetch();
      }
    }
    getNftInfo();
  };

  const onChangePrice = ({target}) => {
    if (!target.value) {
      setPriceNft('');
      return;
    }
    if (regexp.test(target.value)) {
      setPriceNft(target.value);
    }
  };

  const claimNft = async () => {
    const res = await claimNftCert(
      dataNft?.launchpadId,
      nftId,
      dataNft?.nftCertificate,
      collectionInfo?.witnessTypeArg
    );
    if (res) {
      await updateNft({
        id: dataNft.id,
        data: {nftCertificate: ''},
      });
      refetch();
    }
    getNftInfo();
  };

  const history = useNavigate();

  return (
    <div className="detail-nft-page">
      <PageTitle
        sub={dataCollection?.[0]?.name}
        subUrl={`/collection/${dataNft?.collectionId}`}
        title="NFT Details"
        subTitle={dataNft?.name}
      />

      <section className="tf-item-detail">
        <div className="tf-container">
          <div className="row">
            <div className="col-md-12">
              <div className="tf-item-detail-inner row">
                <div className="image col-md">
                  <img className="img-nft" src={dataNft?.url} alt="Binasea" />
                </div>
                <div className="content col-md">
                  <div className="content-top">
                    <div
                      className="author"
                      role="button"
                      onClick={() => history(`/collection/${dataNft?.collectionId}`)}
                    >
                      <img className="img-collection" src={imageUrl?.avatarImg || avt1} alt="Binasea" />
                      <h6 className="title">{dataCollection?.[0]?.name}</h6>
                    </div>
                    {/* <div className="wishlish">
                      <div className="number-wishlish">
                        <i className="fas fa-heart"></i>
                      </div>
                      <div className="option btn-option">
                        <i className="fas fa-ellipsis-h"></i>
                        <div className="option_popup">
                          <Link to="#">Delete</Link>
                          <Link to="#">Edit</Link>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <h2 className="title-detail">{dataNft?.name}</h2>
                  <p className="except">{dataNft?.description}</p>

                  <Tabs className="tf-tab">
                    <TabList className="menu-tab ">
                      <Tab className="tab-title active">
                        <Link to="#">Details</Link>
                      </Tab>
                      {/* <Tab className="tab-title ">
                        <Link to="#">Bids</Link>
                      </Tab> */}
                      {/* <Tab className="tab-title ">
                        <Link to="#">History</Link>
                      </Tab> */}
                    </TabList>

                    <TabPanel key="detail">
                      <div className="tab-details">
                        <div className="top">
                          <div key={'current'} className="author">
                            <div className="heading">Current Owner</div>
                            <div className="infor">
                              <img src={avt1} alt="Binasea" />
                              <h6 className="name">{shortAddress(dataNft?.nftOwner)}</h6>
                            </div>
                          </div>
                          <div key={'current2'} className="author">
                            <div className="heading">Creator</div>
                            <div className="infor">
                              <img src={avt1} alt="Binasea" />
                              <h6 className="name">{shortAddress(dataCollection?.[0]?.authority)}</h6>
                            </div>
                          </div>
                        </div>
                        {dataNft?.attributeKeys?.length > 0 && (
                          <>
                            <div className="title-propepties">Traits</div>
                            <ul className="properties">
                              {dataNft?.attributeKeys?.map((el, index) => (
                                <li>
                                  <div className="attribute-block">
                                    <div className="mb-3"> {dataNft?.attributeKeys[index]}</div>
                                    <div>{dataNft?.attributeValues[index]}</div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                      </div>
                    </TabPanel>
                    {/* <TabPanel>
                      <ul className="tab-bid">
                        {tabBid.map((idx) => (
                          <li key={idx.id}>
                            <div className="box-bid">
                              <div className="image-bid">
                                <img src={idx.avt} alt="Binasea" />
                              </div>
                              <div className="infor">
                                <div className="history">
                                  <span className="price">{idx.price}</span> by <span className="name">{idx.name}</span>
                                </div>
                                <div className="time">{idx.time}</div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </TabPanel> */}
                    {/* <TabPanel key="history">
                      <ul className="tab-history">
                        {tabHistory.map((idx) => (
                          <li key={idx.id}>
                            <div className="box-history">
                              <div className="infor">
                                <div className="img">
                                  <img src={idx.avt} alt="Binasea" />
                                </div>
                                <div className="content">
                                  <h6 className="name">
                                    {idx.name} <span>place a bid</span>
                                  </h6>
                                  <p className="time">8 hours ago</p>
                                </div>
                              </div>
                              <div className="price">
                                <p>4.89 ET</p>
                                <span>= $12.245</span>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </TabPanel> */}
                  </Tabs>

                  <div className="content-bottom">
                    {NFTInfo?.live && (
                      <div className="heading">
                        <h6>Current price</h6>
                        <div className="price">
                          <div className="icon">
                            <img src={SUIIcon} alt="sui" />
                          </div>
                          <span>{dataNft?.saleIndex || 0} SUI</span>
                        </div>
                      </div>
                    )}
                    <div className="button">
                      {dataNft?.nftOwner === addressWallet
                        ? dataCollection?.[0]?.authority === addressWallet &&
                          (!NFTInfo?.live ? (
                            <div className="d-flex">
                              <input value={priceNft} onChange={onChangePrice} type="text" className="input-price" />
                              <button
                                onClick={listingNft}
                                className="tf-button"
                                data-toggle="modal"
                                data-target="#popup_bid"
                              >
                                Listing NFT
                              </button>
                            </div>
                          ) : (
                            <button
                              onClick={listingNft}
                              className="tf-button"
                              data-toggle="modal"
                              data-target="#popup_bid"
                            >
                              Delisting NFT
                            </button>
                          ))
                        : NFTInfo?.live &&
                          dataCollection?.[0]?.authority === dataNft?.nftOwner && (
                            <button onClick={buyNft} className="tf-button" data-toggle="modal" data-target="#popup_bid">
                              Buy
                            </button>
                          )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* 
      <section className="tf-explore-more">
        <div className="tf-container">
          <div className="row">
            <div className="col-md-12">
              <div className="tf-heading">
                <h4 className="heading">Explore More</h4>
              </div>
            </div>

            {data.slice(0, 4).map((idx) => (
              <div key={idx.id} className="col-xl-3 col-lg-4 col-md-6 col-sm-6 ">
                <div className="sc-product style2">
                  <div className="top">
                    <Link to="/item-details-v1" className="tag">
                      {idx.title}
                    </Link>
                    <div className="wish-list">
                      <Link to="#" className="heart-icon"></Link>
                    </div>
                  </div>
                  <div className="bottom">
                    <div className="details-product">
                      <div className="author">
                        <div className="avatar">
                          <img src={idx.avt} alt="images" />
                        </div>
                        <div className="content">
                          <div className="position">Creator</div>
                          <div className="name">
                            {' '}
                            <Link to="#">{shortAddress(dataCollection?.authority)}</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="features">
                    <div className="product-media">
                      <img src={idx.img} alt="images" />
                    </div>
                    <div className="rain-drop1">
                      <img src={icon1} alt="images" />
                    </div>
                    <div className="rain-drop2">
                      <img src={icon2} alt="images" />
                    </div>
                  </div>
                  <div className="bottom-style2">
                    <div className="price">
                      <div className="icon">
                        <img src={icon3} alt="images" />
                      </div>
                      <div className="content">
                        <div className="name">ETH</div>
                        <div className="cash">{idx.price}</div>
                      </div>
                    </div>
                    <div className="product-button">
                      <Link
                        to="#"
                        onClick={() => setModalShow(true)}
                        data-toggle="modal"
                        data-target="#popup_bid"
                        className="tf-button"
                      >
                        {' '}
                        Purchase
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <CardModal show={modalShow} onHide={() => setModalShow(false)} />
      </section> */}
    </div>
  );
}

export default DetailNftPage;
