import React, {createContext, useContext, useState, useMemo, ReactNode, useEffect, FC, useCallback} from 'react';
import {
  JsonRpcProvider,
  SplitCoinTransaction,
  RpcTxnDataSerializer,
  SignerWithProvider,
  Coin,
  MoveCallTransaction,
} from '@mysten/sui.js';
import {toast} from 'react-toastify';
import {
  accountAggregateBalancesSelector,
  accountCoinsSelector,
  convertBalance,
  fetchAllOwnedAndRequiredObjects,
  getID,
  getObjectById,
  ownedObjects,
  selectCoinSetWithCombinedBalanceGreaterThanOrEqual,
  selectCoinWithBalanceEqual,
  selectCoinWithBalanceGreaterThanOrEqual,
} from '@/utils/SuiObject';
import {convertu64, getTimeSecond, useLocalStorageState} from '@/utils/utils';
import {useWallet} from '@suiet/wallet-kit';

import {
  DRA_FOR_SALE,
  DRA_STAKING_POOL_ADDRESS,
  PACKAGE_STAKING_ID,
  DRA_SYSTEM_STATE,
  DRA_TOKEN,
  SuiObjectName,
  GAS_BUDGET_MAX,
  GAS_BUDGET_MIN,
  PACKAGE_NFT_PROTOCOL_ID,
  NFT_FARM_ID,
} from '@/utils/constant';
import {message} from 'antd';

import {NftClient} from '../utils';
import {
  client,
  //keypair,
  //signer,
  //signer2,
  COLLECTION_MANAGER_ID,
  COLLECTION_ID,
  COLLECTION_ID2,
  PACKAGE_OBJECT_ID,
  MARKETPLACE_ID,
  PAID_COIN_ID,
  PAID_COIN_ID2,
} from '../utils/constant';
import {strToByteArray} from '../utils/utils';
import {useBlockchainContext} from './BlockchainContext';

interface AppNFTProtocolContext {}
const NFTFarmContext = createContext<any>({});

export function useNFTFarmContext() {
  return useContext(NFTFarmContext);
}

export const UIConsumer = NFTFarmContext.Consumer;

export function NFTFarmProvider({children}: any) {
  const {addressWallet} = useBlockchainContext();
  const endpoint = process.env.REACT_APP_SUI_ENDPOINT;
  const serializer = new RpcTxnDataSerializer(endpoint);

  const {connected, getAccounts, signAndExecuteTransaction, wallet} = useWallet();

  const getAllObjectOfAddress = async (address: string) => {
    const allObjects = await fetchAllOwnedAndRequiredObjects(address);
    const allSuiObjects = ownedObjects(allObjects, address);
    return allSuiObjects;
  };

  useEffect(() => {}, []);

  const getAllCoinsObjects = async (address) => {
    const allSuiObjects = await getAllObjectOfAddress(address);
    const coins = accountCoinsSelector(allSuiObjects);

    return coins;
  };

  const getAllStakeObjects = async (address) => {
    const allSuiObjects = await getAllObjectOfAddress(address);
    const coins = accountCoinsSelector(allSuiObjects);
    console.log(allSuiObjects);
    return coins;
  };

  const getCoinsObjectsByType = async (address) => {
    const coins = await getAllCoinsObjects(address);
    return coins.filter((el) => Coin.getCoinTypeArg(el) === SuiObjectName.DRAToken);
  };

  const getSuiObjectsByType = async (address) => {
    const coins = await getAllCoinsObjects(address);
    return coins.filter((el) => Coin.getCoinTypeArg(el) === SuiObjectName.SuiToken);
  };

  const executeMoveCallNFTProtocolModule = async (
    moduleName,
    typeArgs: Array<string>,
    methodName: string,
    arg?: any
  ) => {
    try {
      const transaction: any = {
        packageObjectId: NFT_FARM_ID,
        module: moduleName,
        function: methodName,
        typeArguments: typeArgs,
        arguments: arg,
        gasBudget: 10000,
      };

      const result = await signAndExecuteTransaction({
        transaction: {
          kind: 'moveCall',
          data: transaction,
        },
      });
      return result;
    } catch (err) {
      console.log(err);
      message.error('Transaction failed!');
      throw err;
    }
  };

  const stakingNft = async (farmingPoolId, nftId, nftArg) => {
    await executeMoveCallNFTProtocolModule('nft_farm', nftArg, 'nft_staking', [farmingPoolId, nftId, getTimeSecond(),]);

    message.success('Stake NFT successfully');
  };

  const createPoolNft = async (collectionId, rewardAmount, startTime, endTime) => {
    const coinsObjectId = await getCoinsObjectsByType(addressWallet);
    
    const coinObject = selectCoinWithBalanceGreaterThanOrEqual(coinsObjectId, rewardAmount, []);
    const res = await executeMoveCallNFTProtocolModule('nft_farm', [], 'new_nft_farm', [
      collectionId,
      getID(coinObject),
      convertu64(rewardAmount * Math.pow(10, 9)),
      startTime,
      endTime,
      getTimeSecond(),
    ]);
    message.success('Create Pool NFT successfully');
    console.log(res);
    
    return res
  };

  const splitCoin = async (amounts) => {
    const coinsObjectId = await getCoinsObjectsByType(addressWallet);

    const coinObject = selectCoinWithBalanceGreaterThanOrEqual(coinsObjectId, amounts[0], []);
    if (Coin.getBalance(coinObject) !== amounts[0]) {
      const transaction: any = {
        coinObjectId: getID(coinObject),
        splitAmounts: amounts,
        gasBudget: 3000,
      };

      const res = await signAndExecuteTransaction({
        transaction: {
          kind: 'splitCoin',
          data: transaction,
        },
      });
      return res?.effects?.created[0].reference.objectId;
    } else {
      return getID(coinObject);
    }
  };

  const value = {createPoolNft, stakingNft};

  return (
    <NFTFarmContext.Provider value={value}>
      <div className="app-element">{children}</div>
    </NFTFarmContext.Provider>
  );
}

export const withNFTFarmContext = (Component: FC) => {
  return (props: any) => {
    return (
      <NFTFarmContext.Consumer>
        {(globalState) => {
          console.log(globalState, props, 'props');

          return <Component {...globalState} {...props} />;
        }}
      </NFTFarmContext.Consumer>
    );
  };
};
