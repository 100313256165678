//-------------------------------------------
import {Ed25519Keypair, JsonRpcProvider, RawSigner} from '@mysten/sui.js';
import {NftClient} from '.';

export const DRA_TOKEN = process.env.REACT_APP_DRA_TOKEN;
export const DRA_SYSTEM_STATE = process.env.REACT_APP_DRA_SYSTEM_STATE;
export const DRA_STAKING_POOL_ADDRESS = process.env.REACT_APP_DRA_STAKING_POOL_ADDRESS;
export const PACKAGE_STAKING_ID = process.env.REACT_APP_PACKAGE_STAKING_ID;
export const DRA_FOR_SALE = process.env.REACT_APP_DRA_FOR_SALE;
export const PACKAGE_NFT_PROTOCOL_ID = process.env.REACT_APP_PACKAGE_NFT_PROTOCOL_ID;
export const NFT_FARM_ID = process.env.REACT_APP_NFT_FARMING
export const GAS_BUDGET_MAX = 100000;
export const GAS_BUDGET_MIN = 30000;
export const SuiObjectName = {
  SuiToken: '0x2::sui::SUI',
  DRAToken: `${PACKAGE_STAKING_ID}::dra::DRA`,
  DRAStake: `${PACKAGE_STAKING_ID}::dra_staked::StakedAccount`,
  DRANFT: `${PACKAGE_NFT_PROTOCOL_ID}::dragon::DRAGON`,
  DRAPrice: `${PACKAGE_NFT_PROTOCOL_ID}::fixed_price::FixedPriceMarket`,
  DRAUniqueNft: `${PACKAGE_NFT_PROTOCOL_ID}::unique_nft::Unique`,
  inventory: `${PACKAGE_NFT_PROTOCOL_ID}::inventory::Inventory`
};
console.log(process.env.REACT_APP_DRA_SYSTEM_STATE, 'REACT_APP_DRA_SYSTEM_STATE');

const COIN_TYPE = '0x2::coin::Coin';
const COIN_TYPE_ARG_REGEX = /^0x2::coin::Coin<(.+)>$/;

// export const mnemonic = '';
// export const mnemonic2 = '';

export const PACKAGE_OBJECT_ID = '0x9b034a9243b2232a31524b38d474c617d5e9af12';
export const COLLECTION_MANAGER_ID = '0x05b94060968b7e7764159b19de696cf049cbfe0a';
export const COLLECTION_ID = '0xb513dde16efdc6b36785e8f5f633c4b2e508ba98';
export const COLLECTION_ID2 = '0x1fb9d570675010a95f87decdc19b0e8db077460d';
export const MARKETPLACE_ID = '0x0b0dd0e270ead276d2f92a46f5626177c3617890';

// Paid object for signer
export const PAID_COIN_ID = '0x1398484bafa8cac64c968e0b17e452a9a3bde116';
// Paid object for signer2
export const PAID_COIN_ID2 = '0x1792625da7c363c955c867cc2d26436ffeb184a6';

export const provider = new JsonRpcProvider('https://fullnode.devnet.sui.io');
export const client = new NftClient(provider);
